import { Controller } from "stimulus";
import { initSelect } from "./lib/common_custom_select";
import { translations, t } from '../global/translations';
import { requestDatepickers } from "./lib/request_datepickers";

function setLangLinkStep(step) {
    const langLinks = document.querySelectorAll('a[href*="event_requests/single_request?location_id"]')
    for (const langLink of langLinks) {
        langLink.href = langLink.href.replace(/&step=\d+/, '');
        langLink.href = `${langLink.href}&step=${step}`;
    }
}

export default class extends Controller {
    static targets = ['step1', 'step2', 'step3',
        'step1Tab', 'step2Tab', 'step3Tab',
        'companyName', 'companyPosition', 'corporate',
        'nextStep', 'previousStep', 'previousStepEmpty', 'submitRequest'];

    static values = { stepCount: Number };

    connect() {
        const paramStep = new URLSearchParams(window.location.search).get('step');
        this.step = paramStep ? parseInt(paramStep) : 1;

        const corporateCheckboxes = document.getElementsByClassName('checkbox__corporate-request');

        for (const corporateCheckbox of corporateCheckboxes) {
            corporateCheckbox.addEventListener('change', () => {
                for (const corporateCheckbox of corporateCheckboxes) {
                    if (corporateCheckbox.checked) {
                        this.corporateRequest = true;
                        this.corporateTarget.value = true;
                        this.companyNameTarget.classList.remove('hidden');
                        this.companyPositionTarget.classList.remove('hidden');
                        break;
                    }
                    else {
                        this.corporateRequest = false;
                        this.corporateTarget.value = false;
                        this.companyNameTarget.classList.add('hidden');
                        this.companyPositionTarget.classList.add('hidden');
                    }
                }
            });
        }
        initSelect("#seating-select");
        initSelect("#state-select");
        this.showStep(this.step);
        requestDatepickers();
    }

    stepClassesInactive(steps, stepTabs) {
        for (const step of steps) {
            step.classList.add('hidden');
        }
        for (const stepTab of stepTabs) {
            stepTab.classList.remove('text-nepal-800', 'border-nepal-700');
            stepTab.classList.add('text-nepal-200', 'border-nepal-200');
        }
    }

    stepClassesActive(step, stepTab) {
        step.classList.remove('hidden');
        stepTab.classList.remove('text-nepal-200', 'border-nepal-200');
        stepTab.classList.add('text-nepal-800', 'border-nepal-700');
    }

    showStep(step) {
        setLangLinkStep(this.step);
        switch (this.step) {
            case 1:
                this.stepClassesInactive([this.step2Target, this.step3Target], [this.step2TabTarget, this.step3TabTarget]);
                this.stepClassesActive(this.step1Target, this.step1TabTarget);
                break;
            case 2:
                this.stepClassesInactive([this.step1Target, this.step3Target], [this.step1TabTarget, this.step3TabTarget]);
                this.stepClassesActive(this.step2Target, this.step2TabTarget);
                break;
            case 3:
                this.stepClassesInactive([this.step1Target, this.step2Target], [this.step1TabTarget, this.step2TabTarget]);
                this.stepClassesActive(this.step3Target, this.step3TabTarget);
                break;
        }
    }

    validateCheckboxes(checkboxes, indicatorCheckbox, message) {
        let isChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);

        if (!isChecked) {
            indicatorCheckbox.setCustomValidity(message);
            indicatorCheckbox.reportValidity();
            indicatorCheckbox.addEventListener('change', () => {
                indicatorCheckbox.setCustomValidity('');
            });
            indicatorCheckbox.scrollIntoView({ behavior: "instant", block: "center" });
            return false;
        } else {
            indicatorCheckbox.setCustomValidity('');
            checkboxes.forEach(cb => {
                cb.removeAttribute('required')
            })
        }

        return true;
    }

    validatePhone(phone) {
        const phoneRegex = /^(\+|00)(?:[0-9]){6,14}[0-9]$/;
        return phoneRegex.test(phone);
    }

    validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    validateStep1() {
        let valid = document.getElementById('event_request_event_name').value != '';
        if (!valid) {
            document.getElementById('event_request_event_name').setCustomValidity(t(I18n.locale, 'fill-out-field'));
            document.getElementById('event_request_event_name').reportValidity();
            document.getElementById('event_request_event_name').scrollIntoView({ behavior: "instant", block: "center" });
            return false;
        } else {
            document.getElementById('event_request_event_name').setCustomValidity('');
        }

        const checkboxesCorporate = document.getElementsByClassName('checkbox__corporate-request');
        const checkboxesPrivate = document.getElementsByClassName('checkbox__private-request');
        const checkboxes = [...checkboxesCorporate, ...checkboxesPrivate];

        valid = valid && this.validateCheckboxes(checkboxes, checkboxes[0], t(I18n.locale, 'fill-out-multi'));
        return valid;
    }

    validateStep2() {
        const arrivalDate = document.getElementById('arrival-date');
        if (!arrivalDate.value) {
            arrivalDate.click();
            setTimeout(() => {
                arrivalDate.setCustomValidity(t(I18n.locale, 'fill-out-field'));
                arrivalDate.reportValidity();
            }, 100);
            arrivalDate.scrollIntoView({ behavior: "instant", block: "center" });
            return false;
        } else {
            arrivalDate.setCustomValidity('');
        }

        const attendees = document.getElementById('attendees');
        if (!attendees.value) {
            attendees.setCustomValidity(t(I18n.locale, 'fill-out-field'));
            attendees.reportValidity();
            attendees.scrollIntoView({ behavior: "instant", block: "center" });
            return false;
        } else {
            attendees.setCustomValidity('');
        }

        return true;
    }

    validateStep3() {
        document.getElementById('data-protection-alert-request').classList.remove('border', 'border-red-500');

        const radios = document.getElementsByName('event_request[gender]');
        const firstRadio = radios[0];

        let valid = this.validateCheckboxes(radios, firstRadio, t(I18n.locale, 'fill-out-radio'));
        if (!valid) {
            return false;
        }

        valid = document.getElementById('event_request_first_name').value != '';
        if (!valid) {
            document.getElementById('event_request_first_name').setCustomValidity(t(I18n.locale, 'fill-out-field'));
            document.getElementById('event_request_first_name').reportValidity();
            document.getElementById('event_request_first_name').scrollIntoView({ behavior: "instant", block: "center" });
            return false;
        } else {
            document.getElementById('event_request_first_name').setCustomValidity('');
        }

        valid = document.getElementById('event_request_last_name').value != '';
        if (!valid) {
            document.getElementById('event_request_last_name').setCustomValidity(t(I18n.locale, 'fill-out-field'));
            document.getElementById('event_request_last_name').reportValidity();
            document.getElementById('event_request_last_name').scrollIntoView({ behavior: "instant", block: "center" });
            return false;
        } else {
            document.getElementById('event_request_last_name').setCustomValidity('');
        }

        if (this.corporateRequest) {
            valid = document.getElementById('event_request_company_name').value != '';
            if (!valid) {
                document.getElementById('event_request_company_name').setCustomValidity(t(I18n.locale, 'fill-out-field'));
                document.getElementById('event_request_company_name').reportValidity();
                document.getElementById('event_request_company_name').scrollIntoView({ behavior: "instant", block: "center" });
                return false;
            } else {
                document.getElementById('event_request_company_name').setCustomValidity('');
            }
        }

        const validEmail = this.validateEmail(document.getElementById('event_request_email').value);

        if (!validEmail) {
            document.getElementById('event_request_email').setCustomValidity(t(I18n.locale, 'custom-email'));
            document.getElementById('event_request_email').reportValidity();
            document.getElementById('event_request_email').scrollIntoView({ behavior: "instant", block: "center" });
            return false;
        }
        else {
            document.getElementById('event_request_email').setCustomValidity('');
        }

        const validPhone = this.validatePhone(document.getElementById('event_request_tel').value);
        if (!validPhone) {
            document.getElementById('event_request_tel').setCustomValidity(t(I18n.locale, 'custom-phone'));
            document.getElementById('event_request_tel').reportValidity();
            document.getElementById('event_request_tel').scrollIntoView({ behavior: "instant", block: "center" });
            return false;
        }
        else {
            document.getElementById('event_request_tel').setCustomValidity('');
        }


        valid = document.getElementById('data-protection-request').checked;

        if (!valid) {
            document.getElementById('data-protection-request').setCustomValidity(t(I18n.locale, 'fill-out-field'));
            document.getElementById('data-protection-request').reportValidity();
            document.getElementById('data-protection-request').scrollIntoView({ behavior: "instant", block: "center" });
            return false;
        } else {
            document.getElementById('data-protection-request').setCustomValidity('');
        }

        return valid;
    }

    submit(event) {
        if (this.validateStep3()) {
            event.target.classList.add('spinner-active');
            document.getElementById('new_event_request').submit();
        }
    }
    nextStep() {
        if (this.step == 1 && !this.validateStep1()) {
            return;
        }

        if (this.step == 2 && !this.validateStep2()) {
            return;
        }

        if (this.step == 3 && !this.validateStep3()) {
            return;
        }

        this.step++;

        if (this.step == (this.stepCountValue + 1)) {
            this.step = 1;
        }

        if (this.step == 1) {
            this.previousStepTarget.classList.add('hidden');
            this.previousStepEmptyTarget.classList.remove('hidden');
        } else {
            this.previousStepTarget.classList.remove('hidden');
            this.previousStepEmptyTarget.classList.add('hidden');
        }

        if (this.step == 3 || this.step == 0) {
            this.nextStepTarget.classList.add('hidden');
            this.submitRequestTarget.classList.remove('hidden');
        } else {
            this.submitRequestTarget.classList.add('hidden');
            this.nextStepTarget.classList.remove('hidden');
        }

        this.showStep(this.step);
    }
    previousStep() {
        this.step--;

        if (this.step == 0) {
            this.step = this.stepCountValue;
        }

        if (this.step == 1) {
            this.previousStepTarget.classList.add('hidden');
            this.previousStepEmptyTarget.classList.remove('hidden');
        } else {
            this.previousStepEmptyTarget.classList.add('hidden');
            this.previousStepTarget.classList.remove('hidden');
        }

        if (this.step == 3 || this.step == 0) {
            this.nextStepTarget.classList.add('hidden');
            this.submitRequestTarget.classList.remove('hidden');
        } else {
            this.submitRequestTarget.classList.add('hidden');
            this.nextStepTarget.classList.remove('hidden');
        }

        this.showStep(this.step);
    }
}