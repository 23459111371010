import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ["element"];
    connect() {
        this.elementTargets.forEach((element) => {
            element.addEventListener("click", (event) => {
                const currentTarget = event.currentTarget;
                const svg = currentTarget.querySelector("svg");
                const content = currentTarget.querySelector(".dropdown-content");

                this.elementTargets.forEach((loopElement) => {
                    if (loopElement !== currentTarget) {
                        const loopSvg = loopElement.querySelector("svg");
                        const loopContent = loopElement.querySelector(".dropdown-content");

                        loopElement.dataset.open = false;
                        setTimeout(() => {
                            loopSvg.classList.remove("rotate-180");
                        }, 300);
                        $(loopContent).slideUp(300);
                    }
                });

                if (currentTarget.dataset.open === "true") {
                    currentTarget.dataset.open = false;
                    setTimeout(() => {
                        svg.classList.remove("rotate-180");
                    }, 300);
                    $(content).slideUp(300);
                } else {
                    currentTarget.dataset.open = true;
                    setTimeout(() => {
                        svg.classList.add("rotate-180");
                    }, 300);
                    $(content).slideDown(300);
                }
            });
        });
    }

    disconnect() {

    }

    initialize() {

    }
}