export const translations = JSON.parse(`
{
    "en": {
        "fill-out-field": "Please fill out this field",
        "fill-out-multi": "Please choose at least one option",
        "fill-out-radio": "Please choose one option",
        "custom-email": "Please enter a valid email address",
        "custom-phone": "Please enter a valid phone number",
        "more-filters": "more filters",
        "less-filters": "less filters",
        "fav-list-add": "Add to location basket",
        "fav-list-remove" : "Remove from location basket",
        "fav-list-limit": "You can only select 10 locations"
    },
    "de": {
        "fill-out-field": "Bitte dieses Feld ausfüllen",
        "fill-out-multi": "Bitte mindestens eine Option wählen",
        "fill-out-radio": "Bitte eine Option wählen",
        "custom-email": "Bitte eine gültige Email Adresse eingeben",
        "custom-phone": "Bitte eine gültige Telefonnummer eingeben",
        "more-filters": "weitere Filter",
        "less-filters": "weniger Filter",
        "fav-list-add": "Zur Anfrageliste hinzufügen",
        "fav-list-remove" : "Aus der Anfrageliste entfernen",
        "fav-list-limit": "Sie können nur 10 Locations auswählen"
    }
}
`);

export function t(locale, key) {
    return translations[locale][key];
}
