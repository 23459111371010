function requestDatepickers() {
    const datepickers = document.getElementsByClassName('datepicker');

    let picker1 = null;
    let picker2 = null;

    picker1 = new Pikaday({
        field: datepickers[0],
        minDate: moment().toDate(),
        i18n: {
            months: moment.localeData()._months,
            weekdays: moment.localeData()._weekdays,
            weekdaysShort: moment.localeData()._weekdaysShort
        },
        onSelect: (date) => {
            const $departure = $("input[name='event_request\[departing_on\]']");

            if (!$departure.val()) {
                $departure.val(picker1.getMoment().format("YYYY-MM-DD"));
            }

            if (picker2.getMoment().isBefore(picker1.getMoment())) {
                picker2.setDate(picker1.getDate());
            }

            picker2.setMinDate(date);
        }
    });

    picker2 = new Pikaday({
        field: datepickers[1],
        minDate: moment().toDate(),
        i18n: {
            months: moment.localeData()._months,
            weekdays: moment.localeData()._weekdays,
            weekdaysShort: moment.localeData()._weekdaysShort
        },
        onSelect: (date) => {
            const $arrival = $("input[name='event_request\[arriving_on\]']");

            if (!$arrival.val()) {
                $arrival.val(picker2.getMoment().format("YYYY-MM-DD"));
                picker2.setMinDate(date);
            }
        }
    });
}

export { requestDatepickers };