import $ from 'jquery';
import { translations, t } from 'global/translations';

$(function () {
    $('.filter').on("click", function () {
        const $this = $(this);
        $this.toggleClass('filter');
        if ($this.hasClass('filter')) {
            $this.text(t(I18n.locale, 'more-filters'));
        } else {
            $this.text(t(I18n.locale, 'less-filters'));
        }
    });

    let isUpdating = false;
    $("input[name='map_search_selector']").on("change", function (element) {
        if (isUpdating) return;

        isUpdating = true;
        if (element.target.checked) {
            $("input[name='map_search_selector']").prop("checked", true);
            sessionStorage.setItem("search_view", "map");
        } else {
            $("input[name='map_search_selector']").prop("checked", false);
            sessionStorage.setItem("search_view", "list");
        }
        isUpdating = false;
    });
});
