import $ from 'jquery';

$(function () {
    const submitOk = $("#submit-ok");
    const submitError = $("#submit-error");
    const detailForm = $("#newsletter-detail-form");
    const statusWrapper = $("#status-wrapper");

    const clearForm = function () {
        setTimeout(function () {
            $("#firstname").val("");
            $("#lastname").val("");
            $("#email").val("");
            $("#data-protection").prop("checked", false);
            submitOk.hide();
            submitOk.hide();
            statusWrapper.hide();
            //$(".popup__content").height("auto");
            detailForm.show();
        }, 500);
    }

    $("#newsletter-form").on("submit", function (e) {
        e.preventDefault();
        const formData = $(this).serialize();
        $.ajax({
            type: "POST",
            timeout: 5000,
            url: $("#newsletter-form").attr("action"),
            data: formData,
            success: function (response) {
                const html = $.parseHTML(response);
                const h1Content = $(html).find('h1').text();
                const element = $(html).find('table:nth-of-type(2) tr:first td');
                let message = ''

                if (element.text() !== '') {
                    message = element.text();
                }

                if (h1Content === "OK") {
                    if (statusWrapper.is(":hidden")) {
                        detailForm.fadeOut(300);
                        setTimeout(function () {
                            statusWrapper.show();
                            submitOk.fadeIn(300);
                        }, 300);
                    } else {
                        detailForm.fadeOut(300);
                        setTimeout(function () {
                            submitError.hide();
                            submitOk.fadeIn(300);
                        }, 300);
                    }
                } else {
                    if (message) {
                        $('#error-message').text(message);
                    }
                    if (statusWrapper.is(":hidden")) {
                        setTimeout(function () {
                            statusWrapper.show();
                            submitError.fadeIn(300);
                        }, 300);
                    } else {
                        setTimeout(function () {
                            submitError.fadeIn(300);
                        }, 300);
                    }
                }
            },
            error: function (xhr, status, error) {
                $('#error-message').append(': Verbindungsfehler')
                if (statusWrapper.is(":hidden")) {
                    setTimeout(function () {
                        statusWrapper.show();
                        submitError.fadeIn(300);
                    }, 300);
                } else {
                    setTimeout(function () {
                        submitOk.hide();
                        submitError.fadeIn(300);
                    }, 300);
                }

            }
        });
    });
});