function toggleSelect(b, caret) {
    b.toggle();

    if (caret.dataset.value === "closed") {
        caret.innerHTML = `<i class="fas fa-caret-up fa-xl mb-[1px] mr-1 text-nepal-800"></i>`;
        caret.dataset.value = "open";
    } else {
        caret.innerHTML = `<i class="fas fa-caret-down fa-xl mb-[1px] mr-1 text-nepal-800"></i>`;
        caret.dataset.value = "closed";
    }
}

function initSelect(IdSelector) {
    // Init select
    //$(`${IdSelector} .hiddenpicker option:selected`)[0].removeAttribute("selected")

    const a = $(`${IdSelector} #a`);
    const b = $(`${IdSelector} #b`);
    b.toggle();

    const btnSelect = $(`${IdSelector} .btn-select`);
    const caret = $(`${IdSelector} #caret`);
    const hiddenpickerOption = $(`${IdSelector} .hiddenpicker option`);

    const optionArray = [];
    hiddenpickerOption.each(function () {
        const img = $(this).attr("data-thumbnail");
        if (img) {
            const text = this.innerText;
            const value = $(this).val();
            const item = '<li><img src="' + img + '" alt="" value="' + value + '"/><span>' + text + '</span></li>';
            optionArray.push(item);
        } else {
            const text = this.innerText;
            const value = $(this).val();
            const item = '<li><span>' + text + '</span></li>';
            optionArray.push(item);
        }
    });

    a.html(optionArray);

    btnSelect.html(optionArray[0]);

    const aLi = $(`${IdSelector} #a li`);
    const sel = $(`${IdSelector} .hiddenpicker`);

    aLi.click(function (e) {
        let value = "";
        const img = $(this).find('img').attr("src");

        if (img) {
            value = $(this).find('img').attr('value');
        } else {
            value = $(this).find('span').text();
        }

        const text = this.innerText;
        let item = "";
        if (img) {
            item = '<li><img src="' + img + '" alt="" /><span>' + text + '</span></li>';
        } else {
            item = '<li><span>' + text + '</span></li>';
        }

        btnSelect.html(item);
        btnSelect.attr('value', value);

        hiddenpickerOption.removeAttr("selected");
        sel.val(value);
        const finder = hiddenpickerOption.filter(`[value='${value}']`);
        finder.attr("selected", "");
        toggleSelect(b, caret[0]);

        const action = $(IdSelector).data("action");
        if (action === 'location') {
            const url = finder.data('url');
            window.location = url;
        } else if (action === 'location-init') {
            $(IdSelector).data("action", "location");
            toggleSelect(b, caret[0]);
        }

        e.preventDefault();
    });

    btnSelect.click(function (e) {
        toggleSelect(b, caret[0]);
        e.preventDefault();
    });

    $(caret).click(function (e) {
        toggleSelect(b, caret[0]);
        e.preventDefault();
    });

    const action = $(IdSelector).data("action");
    if (action === 'location') {
        const sort = new URLSearchParams(window.location.search).get('q[s]')

        switch(sort) {
            case "id asc":
                $(`${IdSelector} .hiddenpicker option:eq(1)`).attr("selected", "");
                $(IdSelector).data("action", "location-init");

                aLi.eq(1).trigger("click");
                break;
            case "location_i18ns_name asc":
                $(`${IdSelector} .hiddenpicker option:eq(2)`).attr("selected", "");
                $(IdSelector).data("action", "location-init");

                aLi.eq(2).trigger("click");
                break;
            case "location_i18ns_name desc":
                $(`${IdSelector} .hiddenpicker option:eq(3)`).attr("selected", "");
                $(IdSelector).data("action", "location-init");

                aLi.eq(3).trigger("click");
                break;
            default:
                $(`${IdSelector} .hiddenpicker option:first`).attr("selected", "");

                break;
        }
    } else {
        $(`${IdSelector} .hiddenpicker option:first`).attr("selected", "");
    }

}

export { initSelect };