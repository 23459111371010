import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    url: String,
    id: Number
  }

  connect() {

  }

  async remove(e) {
    let elem = e.currentTarget;
    const response = await fetch(this.urlValue);

    if (response.status == 200) {
      document.getElementById(`fav-list-location-${this.idValue}`).remove();
      const data = await response.json();
      //document.getElementById('fav-list-count').textContent = data.length;

      const favListCounts = document.querySelectorAll('.fav-list-count');
      for (let favListCount of favListCounts) {
        favListCount.textContent = data.length;
      }

      if (data.length == 0) {
        location.reload(true);
      }
    }

  }
}