import { Controller } from "stimulus";
import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/swiper-bundle.min.css";

function toggleOpacity(element) {
  if (element.classList.contains('opacity-100')) {
    element.classList.remove('opacity-100');
    element.classList.add('opacity-0');
    setTimeout(function () {
      element.classList.add('hidden');
    }, 600);

  } else {
    element.classList.remove('hidden');
    setTimeout(function () {
      element.classList.remove('opacity-0');
      element.classList.add('opacity-100');
    }, 1);
  }
}

export default class extends Controller {
  static targets = ["galleryWrapper"];

  connect() {
    //Disable Swiper warnings
    this.restoreWarn = console.warn;
    console.warn = function () { };
    Swiper.use([Navigation, Pagination, Scrollbar, A11y]);
  };

  disconnect() {
    //Restore Swiper warnings
    console.warn = this.restoreWarn;
  }

  close(event) {
    //this.galleryWrapperTarget.classList.add("hidden");

    toggleOpacity(this.galleryWrapperTarget);
    document.getElementsByTagName("body")[0].classList.remove("overflow-hidden");
    this.swiper.destroy(false, false);
    setTimeout(function () {
      document.getElementById('back-to-top-a').classList.remove('hidden');
    }, 200);
  };
  open(event) {
    document.getElementById('back-to-top-a').classList.add('hidden');
    let slideTo = event.params.index || 0;
    document.getElementsByTagName("body")[0].classList.add("overflow-hidden");

    this.swiper = new Swiper('.swiper', {
      direction: 'horizontal',
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      preventClicks: false,
      preventClicksPropagation: false,
      centeredSlides: true,
    });

    //this.galleryWrapperTarget.classList.remove("hidden");
    toggleOpacity(this.galleryWrapperTarget);
    this.swiper.slideTo(slideTo);
    this.swiper.attachEvents()
    this.swiper.updateSlides();
    this.swiper.updateSlidesClasses();
  };
}
