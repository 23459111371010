import { Controller } from 'stimulus';

function hide_show_location_direct_communication(value, element, checkbox) {
    if (value === "No") {
        element.style.display = "none";
        checkbox.checked = false;
    } else {
        element.style.display = '';
        checkbox.checked = true;
    }
}

export default class LocationResponseController extends Controller {
    static targets = ['acceptedDiv', 'acceptedCheck', 'isAvailable'];

    connect() {
        hide_show_location_direct_communication(this.isAvailableTarget.value, this.acceptedDivTarget, this.acceptedCheckTarget);
    }

    toggle(e) {
        if (e.target.value === 'No') {
            this.isAcceptedTarget.checked = false;
        } else {
            this.isAcceptedTarget.checked = true;
        }
    }
    // Define your controller actions here
}