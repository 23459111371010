import { Controller } from "stimulus";
import { translations, t } from '../global/translations';

export default class extends Controller {
    static values = {
        url: String,
        countUrl: String,
        enabled: Boolean,
        id: Number
    }


    connect() {
    }
    async add(e) {
        const elem = e.currentTarget;
        let favListCount = parseInt(document.getElementsByClassName('fav-list-count')[0].textContent)

        if (!this.enabledValue && favListCount >= 10) {
            alert(t(I18n.locale, 'fav-list-limit'));
            return;
        }

        let svgElement = elem.querySelector('svg');
        let txtElement = elem.querySelector('span');
        if (this.enabledValue) {
            svgElement.classList.remove('fa-solid');
            svgElement.classList.add('fa-regular');
            txtElement.textContent = t(I18n.locale, 'fav-list-add');
            this.enabledValue = false;
        } else {
            svgElement.classList.remove('fa-regular');
            svgElement.classList.add('fa-solid');
            txtElement.textContent = t(I18n.locale, 'fav-list-remove');
            this.enabledValue = true;
        }
        const response = await fetch(this.urlValue);

        if (response.status == 200) {
            const data = await response.json();
            //document.getElementById('fav-list-count').textContent = data.length;

            const favListCounts = document.querySelectorAll('.fav-list-count');
            let hasData = false;
            for (let favListCount of favListCounts) {
                favListCount.textContent = data.length;
                if (data.length > 0) {
                    hasData = true;
                    document.querySelector("#fav-list svg").dataset.prefix = "fas";
                    document.querySelector("#fav-list svg").classList.add("fa-beat");
                } else {
                    hasData = false;
                    document.querySelector("#fav-list svg").dataset.prefix = "far";
                    document.querySelector("#fav-list svg").classList.remove("fa-beat");
                }
            }
            if (hasData) {
                const heartElems = document.querySelectorAll(".fav-list-heart");
                heartElems.forEach((elem) => {
                    elem.classList.remove("hidden")
                    elem.querySelector("svg").dataset.prefix = "fas";
                    elem.querySelector("svg").classList.add("fa-beat");
                });
            } else {
                const heartElems = document.querySelectorAll(".fav-list-heart");
                heartElems.forEach((elem) => {
                    elem.classList.add("hidden")
                    elem.querySelector("svg").dataset.prefix = "far";
                    elem.querySelector("svg").classList.remove("fa-beat");
                });
            }
        }
    }
}