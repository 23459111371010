import { Controller } from "stimulus";
import { initSelect } from "./lib/common_custom_select";

export default class extends Controller {
    connect() {
        initSelect("#sort-select");

        const select = document.getElementById('sort-dropdown');
        const options = select.options;
    }
}
